@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

$primary: #4BD1E5;
$font-family-sans-serif: "Source Code Pro";
$font-family-title: "Source Code Pro";
$navbar-nav-link-padding-x: 24px;

$body-bg-dark: #282A37;
$body-color-dark: #DCE1DF;
$dark: #282A37;

$border-radius: 4px;
$border-color: #4c62976f;

// Colors
$blue: #4BD1E5;
$green: #00A75B;
$orange: #F9B04F;
$red: #E06C75;
$pink: #E766C3;
$purple: #B970FF;
$comment: #4C6297;

@import "~bootstrap/scss/bootstrap";
