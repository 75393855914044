@import "./variables";

// Common
.color-blue {
  color: $blue !important;
}
.color-green {
  color: $green !important;
}
.color-orange {
  color: $orange !important;
}
.color-pink {
  color: $pink !important;
}
.color-purple {
  color: $purple !important;
}
.color-comment {
  color: $comment !important;
}
.color-default {
  color: $body-color-dark !important;
}
.container-default {
  max-width: 1200px;
  padding: 0 24px;
}
.title {
  color: $orange;
  font-weight: 400;
  font-size: 40px;
  font-style: italic;
  margin-bottom: 25px;
  span {
    color: $body-color-dark;
    font-style: normal;
  }
  @media screen and (max-width: 530px) {
    font-size: 28px;
  }
}
.btn {
  padding: 12px 25px;
  border-width: 2px;
  font-weight: 400;
  font-size: 20px;
  &.btn-link {
    padding: 0;
    transition: all ease 0.3s;
    opacity: 1;
    &:hover {
      background-color: initial;
      opacity: 0.6;
    }
  }
  svg {
    position: relative;
    top: -2px;
  }
}
p {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
a {
  opacity: 1;
  transition: all ease 0.3s;
  &:hover {
    opacity: 0.6;
  }
}
.form-control {
  background-color: none;
  border: 1px solid $border-color;
  padding: 16px 24px;
  border-radius: $border-radius;
  font-size: 18px;
  transition: all ease 0.3s;
  &.has-data {
    background-color: $border-color;
  }
  &:hover:not(.has-data) {
    background-color: #4c62971f;
  }
  &:focus {
    border: 1px solid $border-color;
    box-shadow: none;
  }
  &::placeholder {
    color: #546ba2;
  }
}
.cursor {
  @media screen and (max-width: 768px) {
    display: none;
  }
  background: radial-gradient(circle, #5a5f8215 0%, transparent 60%);
  width: 1000px;
  height: 1000px;
  position: fixed;
  border-radius: 10000px;
  z-index: 100000;
  pointer-events: none;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 0.1s;
}

// Navigation
.navbrand {
  opacity: 1 !important;
}
.navbar {
  padding: 12px 0 16px 0;
  &-nav {
    margin-top: 8px;
    .nav-link {
      opacity: 1;
      font-weight: 500;
      color: $comment;
      transition: all ease 0.3s;
      font-size: 18px !important;
      @media screen and (min-width: 768px) and (max-width: 992px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      &.github.active {
        color: $comment;
        &:hover {
          color: $body-color-dark;
        }
      }
      span {
        color: $dark;
        transition: all ease 0.3s;
      }
      &:hover {
        color: $body-color-dark;
        span {
          color: $dark;
        }
      }
      &.active {
        color: $orange;
        font-style: italic;
        span {
          color: $body-color-dark;
          font-style: normal;
        }
      }
    }
  }
}

// Hero section
.hero {
  padding-top: 180px;
  border-bottom: 1px solid $border-color;
  padding-bottom: 120px;
  &--pre-title {
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    font-family: $font-family-title;
    color: $comment;
    line-height: normal;
  }
  &--title {
    font-size: 44px;
    font-weight: 400;
    font-family: $font-family-title;
    line-height: normal;
    height: 220px;
    @media screen and (min-width: 526px) {
      height: 150px;
    }
    @media screen and (max-width: 312px) {
      height: 270px;
    }
  }
  &--description {
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 60px;
    color: $comment;
    span {
      color: $body-color-dark;
    }
    @media screen and (min-width: 755px) {
      max-width: 560px;
    }
  }
  .typing {
    &::after {
      color: $body-color-dark;
    }
  }
}

// About me section
.aboutme {
  padding: 120px 0px;
  border-bottom: 1px solid $border-color;
  .experience {
    font-size: 24px;
    margin: 60px 0;
    @media screen and (max-width:410px) {
      font-size: 21px;
    }
    .space {
      display: inline;
      @media screen and (max-width:684px) {
        display: block;
      }
    }
    
  }
  .tools-container {
    margin-top: 60px;
    display: inline-flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    .tool {
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 6px 14px;
      display: flex;
      align-items: center;
      transition: all ease 0.3s;
      svg {
        margin-right: 12px;
        path {
          transition: all ease 0.3s;
        }
      }
      @media only screen and (min-width: 767px) {
        padding: 8px 20px;
        &:hover {
          cursor: default;
          background-color: $border-color;
          // path {
          //   fill: $primary;
          // }
        }
      }
    }
  }
}

// Projects Section
.projects {
  padding: 120px 0px;
  border-bottom: 1px solid $border-color;
  .project {
    &--container {
      margin-top: 40px;
      align-items: center;
    }
    &--image {
      background-color: #4c629724;
      padding: 80px 42px;
      border-radius: 12px;
      img {
        max-width: 100%;
        border-radius: 8px;
      }
      @media screen and (max-width: 768px) {
        padding: 40px 20px;
      }
    }
    &--info {
      @media screen and (max-width: 768px) {
        margin-top: 24px;
        margin-bottom: 48px;
      }
    }
    &--title {
      font-size: 32px;
      margin-bottom: 24px;
      color: $pink;
      line-height: normal;
    }
    &--client {
      font-size: 18px;
      margin-bottom: 24px;
      color: $green;
    }
    &--tools {
      color: $comment;
      font-size: 18px;
    }
  }
}

// Contact section
.contact {
  padding: 120px 0px;
  .title {
    padding-bottom: 40px;
  }
  .btn-submit:disabled {
    background-color: inherit;
    color: $comment !important;
    text-decoration: none;
  }
  .form-submited {
    margin-top: 40px;
    &.success {
      color: $green;
    }
    &.error {
      color: $red;
    }
  }
}
